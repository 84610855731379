import { SensorsConfigWrapper } from "./view"
import { SensorsConfigProvider } from "./context/SensorConfigContext"
import styled from "styled-components"
import Container from "../../components/ui/Container"
import { NewHeader } from "../../components/NewHeader"
import Column from "../../components/ui/Column"
import Row from "../../components/ui/Row"
import { useAppTranslate } from "../../translate/useAppTranslate"

const Card = styled.div`
  height: auto;
  border: solid 1px ${(props) => props.theme.palette.gray[400]};
  border-radius: 4px 4px 4px 4px;
  background-color: ${(props) => props.theme.palette.gray[50]};
  padding: 20px 16px;
`

export const SensorsConfig = () => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.sensors.title,
    subTitle: lateralMenu.routes.sensors.config,
  }

  return (
    <SensorsConfigProvider>
      <Container>
        <Row>
          <NewHeader page={page} />
        </Row>
        <Row>
          <Column md='24' lg='24' xl='24'>
            <Card>
              <SensorsConfigWrapper />
            </Card>
          </Column>
        </Row>
      </Container>
    </SensorsConfigProvider>
  )
}
