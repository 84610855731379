import { NewHeader } from "../../../../components/NewHeader"
import { DashboardHeader } from "../../../Dashboard/view/styles"
import Container from "../../../../components/ui/Container"
import Column from "../../../../components/ui/Column"
import { TabList } from "../../../../components/ui/TabList"
import { Tabs } from "../../../../components/ui/Tabs"
import { TabPanels } from "../../../../components/ui/TabPanels"
import { TabPanel } from "../../../../components/ui/TabPanel"
import { Tab } from "../../../../components/ui/Tab"
import { useEffect, useMemo, useRef, useState } from "react"
import { TreeContainer } from "./components/TreeContainer"
import { HistoryCard } from "./components/HistoryCard"
import {
  AssetsContainer,
  Content,
  Wrapper,
  MessageContainer,
  HistoryComponent,
  ColumnsWrapper,
} from "./styles"
import { AssetDetails, AssetDetailsSkeleton } from "./components/Details"
import { CustomDataProvider } from "./components/TreeAssets/CustomDataProvider"
import { Button } from "../../../../components/ui/Button"
import { TreeItemIndex, TreeRef } from "react-complex-tree"
import { HistoryCardSkeleton } from "./components/Skeleton/HistoryCard"
import { TreeAssetsSkeleton } from "./components/Skeleton/TreeAssets"
import { useFetchAssetsQuery } from "../../../../store/store"
import { useNavigate } from "react-router-dom"
import { TreeContent } from "./components/TreeContainer/components/TreeContent"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

export const Assets = () => {
  const { assets, lateralMenu } = useAppTranslate()

  const [open, setOpen] = useState(true)
  const [assetDetail, setAssetDetails] = useState<string | undefined>()
  const tree = useRef<TreeRef>(null)
  const navigate = useNavigate()

  const page = {
    title: lateralMenu.routes.assets.title,
  }

  const {
    data: listAssets,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useFetchAssetsQuery()

  const canRenderTree = useMemo(
    () => !isError && !isLoading && !isFetching,
    [isError, isLoading, isFetching]
  )

  useEffect(() => {
    if (!listAssets) return

    const assetsIds = Object.keys(listAssets)

    if (assetsIds.length === 0) return

    const id = assetsIds[0]

    setAssetDetails(listAssets[id].data.id)
  }, [listAssets])

  const onClick = () => {
    setOpen((prev) => !prev)
  }

  const dataProvider = useMemo(() => {
    if (!listAssets) {
      return new CustomDataProvider({})
    } else {
      return new CustomDataProvider(listAssets)
    }
  }, [listAssets])

  const handleAddItemClick = (itemIdx: TreeItemIndex) => {
    navigate(`/assets/create/${itemIdx}`)
  }

  const setClickAsset = (id: string) => {
    setAssetDetails(id)
  }

  return (
    <Container>
      <AssetsContainer>
        <DashboardHeader>
          <NewHeader page={page} />
        </DashboardHeader>

        <ColumnsWrapper>
          <Column sm='19' md='24' lg='19' xl='19'>
            <Wrapper>
              <TreeContainer open={open} onClick={onClick}>
                {isError && (
                  <MessageContainer>
                    {assets.tree.error}
                    <Button variant='link' onClick={refetch}>
                      {assets.reload}
                    </Button>
                  </MessageContainer>
                )}

                {(isLoading || isFetching) && <TreeAssetsSkeleton />}

                {canRenderTree && (
                  <TreeContent
                    dataProvider={dataProvider}
                    handleAsset={setClickAsset}
                    tree={tree}
                    handleOnClick={handleAddItemClick}
                  />
                )}
              </TreeContainer>

              <Content>
                <Tabs>
                  <TabList>
                    <Tab>{assets.details.title}</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      {assetDetail ? (
                        <AssetDetails assetId={assetDetail} />
                      ) : !isError ? (
                        <AssetDetailsSkeleton />
                      ) : (
                        <MessageContainer>
                          {assets.details.error}
                        </MessageContainer>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Content>
            </Wrapper>
          </Column>

          <Column sm='5' md='24' lg='5' xl='5'>
            <HistoryComponent>
              {isLoading && <HistoryCardSkeleton />}

              {assetDetail && <HistoryCard assetId={assetDetail} />}

              {isError && (
                <MessageContainer>{assets.history.error}</MessageContainer>
              )}
            </HistoryComponent>
          </Column>
        </ColumnsWrapper>
      </AssetsContainer>
    </Container>
  )
}
