import { Title } from "../../../../../components/Title"
import {
  Actions,
  ConfigButton,
  Container,
  Header,
  IconButton,
  Services,
} from "./styles"
import { IconService } from "../../../../../components/IconService"
import { Gear, Trash } from "phosphor-react"
import { SensorService } from "../../../../../utils/entities/assets"
import { useRemoveSensorMutation } from "../../../../../store/api/assets/assets-details"
import { ModalHedro } from "../../../../../components/ModalHedro"
import { useState } from "react"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

interface AssetWithSensorProps {
  servicesConfigured?: SensorService[]
  sensorName: string
  assetId: string
  assetName: string
}

export const AssetWithSensor = ({
  servicesConfigured,
  sensorName,
  assetId,
  assetName,
}: AssetWithSensorProps) => {
  const { assets } = useAppTranslate()

  const [removeSensor, mutation] = useRemoveSensorMutation()

  const [open, setOpen] = useState(false)

  return (
    <>
      {open && (
        <ModalHedro
          onClose={() => setOpen(false)}
          onClick={() => removeSensor(assetId)}
          actionBar={assets.sensors.deleteSensor}
          title={assets.sensors.deleteSensor}
          disableAction={mutation.isLoading}
        >
          <Text fontSize='sm' fontWeight='regular' color='gray.700'>
            {`${assets.sensors.deleteModalMessage.part1} ${sensorName} ${assets.sensors.deleteModalMessage.part2} "${assetName}".`}
          </Text>
        </ModalHedro>
      )}
      <Header>
        <Title label='Sensor' size='sm' weight='medium' />
        <Actions>
          <ConfigButton onClick={() => {}}>
            <Gear size={22} weight='bold' />
          </ConfigButton>
          <IconButton onClick={() => setOpen(true)}>
            <Trash size={22} weight='bold' />
          </IconButton>
        </Actions>
      </Header>
      <Container>
        <Title label={sensorName} size='md' weight='medium' />
        <Services>
          {servicesConfigured?.map((element, index) => (
            <IconService
              key={index}
              serviceType={element.name}
              isConfigured={element.isConfigured}
            />
          ))}
        </Services>
      </Container>
    </>
  )
}
