import { Navigate, useParams } from "react-router-dom"
import { NewHeader } from "../../../../components/NewHeader"
import Container from "../../../../components/ui/Container"
import Row from "../../../../components/ui/Row"
import { DashboardHeader } from "../../../Dashboard/view/styles"
import { useFetchAssetDetailsQuery } from "../../../../store/store"
import Column from "../../../../components/ui/Column"
import { useForm } from "react-hook-form"
import { assetSchema, AssetSchema, getDefaultValues } from "../utils/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect } from "react"
import { AssetForm, AssetFormSkeleton } from "../components/AssetForm"
import { Actions } from "./components/Actions"
import { ErrorMessage } from "../components/ErrorMessage"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

export const ConfigureAsset = () => {
  const { id } = useParams()

  if (!id) return <Navigate to='/assets' />

  return <Configure id={id} />
}

const Configure = ({ id }: { id: string }) => {
  const {
    data: details,
    isLoading,
    isError,
    refetch,
  } = useFetchAssetDetailsQuery(id)

  const { assets, lateralMenu } = useAppTranslate()

  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
  })

  const page = {
    title: lateralMenu.routes.assets.title,
    subTitle: lateralMenu.routes.assets.configure,
  }

  useEffect(() => {
    if (!details) return
    form.reset(getDefaultValues(details))
  }, [details, form])

  return (
    <Container>
      <Row>
        <DashboardHeader>
          <NewHeader page={page} />
          {details && (
            <Actions
              id={id}
              isRoot={details.isRoot}
              handleFormSubmit={form.handleSubmit}
            />
          )}
        </DashboardHeader>
      </Row>

      <Row>
        <Column md='24' lg='24' xl='24'>
          {details && <AssetForm form={form} isConfigure />}
          {isLoading && <AssetFormSkeleton />}
          {isError && (
            <ErrorMessage
              message={assets.details.error}
              handleReload={refetch}
            />
          )}
        </Column>
      </Row>
    </Container>
  )
}
