import { CloudX } from "@phosphor-icons/react"
import { Button } from "../../../../../components/ui/Button"
import { IconComponent } from "../../../../../components/ui/Icon"
import theme from "../../../../../styles/theme"
import { InfoContainer, MessageContainer } from "./styles"

interface ErrorMessageProps {
  message: string
  handleReload: () => void
}

export const ErrorMessage = ({ message, handleReload }: ErrorMessageProps) => {
  return (
    <MessageContainer>
      <IconComponent
        icon={<CloudX size={59} color={theme.palette.primary.main} />}
      />
      <InfoContainer>
        <p>{message}</p>
        <Button variant='link' onClick={handleReload}>
          Roloud
        </Button>
      </InfoContainer>
    </MessageContainer>
  )
}
