import { Controller } from "react-hook-form"
import { useRegisterCompany } from "./hooks/useRegisterCompany"
import { LoadingContainer, ContainerWraper, Title } from "./styles"
import { Input } from "../../components/Input"
import { Select } from "../../components/Select"
import FormButton from "../../components/FormButton"
import ModalConfirmCompany from "./components/ModalConfirmCompany"
import { NewHeader } from "../../components/NewHeader"
import Container from "../../components/ui/Container"
import Row from "../../components/ui/Row"
import { useAppTranslate } from "../../translate/useAppTranslate"
import styled from "styled-components"
import Column from "../../components/ui/Column"

const Card = styled.div`
  height: auto;
  border: solid 1px ${(props) => props.theme.palette.gray[400]};
  border-radius: 6px 6px 6px 6px;
  background-color: ${(props) => props.theme.palette.gray[50]};
  padding: 20px 16px;
`

export const RegisterCompany = (): JSX.Element => {
  const {
    isOpen,
    loadingPage,
    control,
    products,
    company,
    loadingRequest,
    closeModal,
    handleSubmit,
    handleCreateCompany,
  } = useRegisterCompany()

  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.manager.title,
    subTitle: lateralMenu.routes.manager.company,
  }

  return (
    <Container>
      <Row>
        <NewHeader page={page} />
      </Row>
      <Row>
        <Column md='24' lg='24' xl='24'>
          <Card>
            {company && (
              <ModalConfirmCompany
                open={isOpen}
                data={company}
                loading={loadingRequest}
                onClose={closeModal}
                onConfirm={handleCreateCompany}
              />
            )}
            {loadingPage ? (
              <LoadingContainer>
                <h1>Carregando...</h1>
              </LoadingContainer>
            ) : (
              <ContainerWraper onSubmit={handleSubmit}>
                <Title>
                  <h1>Informações</h1>
                </Title>

                <Controller
                  name='name'
                  control={control}
                  render={({ field }) => {
                    return <Input label='Nome' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='cnpj'
                  control={control}
                  render={({ field }) => {
                    return <Input label='CNPJ' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='occupation'
                  control={control}
                  render={({ field }) => {
                    return <Input label='Ocupação' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='state'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        label='Estado de registro'
                        placeholder=''
                        {...field}
                      />
                    )
                  }}
                />

                <Title>
                  <h1>Endereço</h1>
                </Title>

                <Controller
                  name='cep'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        type='number'
                        label='CEP'
                        placeholder=''
                        {...field}
                      />
                    )
                  }}
                />

                <Controller
                  name='country'
                  control={control}
                  render={({ field }) => {
                    return <Input label='País' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='adressState'
                  control={control}
                  render={({ field }) => {
                    return <Input label='Estado' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='city'
                  control={control}
                  render={({ field }) => {
                    return <Input label='Cidade' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='neighborhood'
                  control={control}
                  render={({ field }) => {
                    return <Input label='Bairro' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='street'
                  control={control}
                  render={({ field }) => {
                    return <Input label='Rua' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='number'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        type='number'
                        label='Número'
                        placeholder=''
                        {...field}
                      />
                    )
                  }}
                />

                <Controller
                  name='complement'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input label='Complemento' placeholder='' {...field} />
                    )
                  }}
                />

                <Title>
                  <h1>Vendas</h1>
                </Title>

                <Controller
                  name='product'
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Select
                        values={products.map((product) => product.name)}
                        placeholder='Selecione um produto'
                        onChange={(value) =>
                          onChange(Number(value.target.value))
                        }
                        onBlur={onBlur}
                        value={value}
                        ref={ref}
                      />
                    )
                  }}
                />

                <Controller
                  name='description'
                  control={control}
                  render={({ field }) => {
                    return <Input label='Descrição' placeholder='' {...field} />
                  }}
                />

                <Controller
                  name='amount'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        type='number'
                        label='Quantidade'
                        placeholder=''
                        {...field}
                      />
                    )
                  }}
                />

                <FormButton text='ADCIONAR EMPRESA' type='submit' />
              </ContainerWraper>
            )}
          </Card>
        </Column>
      </Row>
    </Container>
  )
}
