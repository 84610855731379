import { NewHeader } from "../../../../components/NewHeader"
import Container from "../../../../components/ui/Container"
import Row from "../../../../components/ui/Row"
import { DashboardHeader } from "../../../Dashboard/view/styles"
import Column from "../../../../components/ui/Column"
import { AssetForm } from "../components/AssetForm"
import { useForm } from "react-hook-form"
import { AssetSchema, assetSchema, defaultValues } from "../utils/schema"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { Actions } from "./components/Actions"

export const CreateAsset = () => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.assets.title,
    subTitle: lateralMenu.routes.assets.create,
  }

  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
    defaultValues,
  })

  return (
    <Container>
      <Row>
        <DashboardHeader>
          <NewHeader page={page} />
          <Actions handleFormSubmit={form.handleSubmit} />
        </DashboardHeader>
      </Row>

      <Row>
        <Column md='24' lg='24' xl='24'>
          <AssetForm form={form} />
        </Column>
      </Row>
    </Container>
  )
}
