import Container from "../../../../components/ui/Container"
import Row from "../../../../components/ui/Row"
import Column from "../../../../components/ui/Column"
import { NewHeader } from "../../../../components/NewHeader"
import { useAppTranslate } from "../../../../translate/useAppTranslate"

import { ModalRename } from "../components/ModalRename"
import { useGatewayController } from "../controllers/gatewayController"
import { GatewayContent } from "../components/GatewayContent"

export const WrapperGateways = () => {
  const { lateralMenu } = useAppTranslate()

  const page = {
    title: lateralMenu.routes.gateways,
  }

  const {
    isOpenModalRename,
    handleCloseModal,
    renameGateway,
    handleEditNameGateway,
    isLoading,
    handleOpenModal,
    setSelectedItems,
    listGateways,
    isError,
    isFetching,
    refetch,
  } = useGatewayController()

  return (
    <Container>
      <Row>
        <NewHeader page={page} />
      </Row>
      <Row>
        <Column md='24' lg='24' xl='24'>
          <GatewayContent
            handleOpenModal={handleOpenModal}
            setSelectedItems={setSelectedItems}
            listGateways={listGateways}
            error={isError}
            isFetching={isFetching}
            refetch={refetch}
          />
        </Column>
      </Row>
      {isOpenModalRename && (
        <ModalRename
          handleCloseModal={handleCloseModal}
          renameGateway={renameGateway}
          handleEditNameGateway={handleEditNameGateway}
          isLoading={isLoading}
        />
      )}
    </Container>
  )
}
